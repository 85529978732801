import React from "react";
import theme from "theme";
import { Theme, Text, Em, Hr, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				FocusFrame Rentals
			</title>
			<meta name={"description"} content={"У FocusFrame Rentals ми надаємо вам високоякісні інструменти та простори, щоб втілити ваше бачення в життя."} />
			<meta property={"og:title"} content={"FocusFrame Rentals"} />
			<meta property={"og:description"} content={"У FocusFrame Rentals ми надаємо вам високоякісні інструменти та простори, щоб втілити ваше бачення в життя."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-3.jpg?v=2024-06-11T09:10:49.109Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-3.jpg?v=2024-06-11T09:10:49.109Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-3.jpg?v=2024-06-11T09:10:49.109Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-3.jpg?v=2024-06-11T09:10:49.109Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-3.jpg?v=2024-06-11T09:10:49.109Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-3.jpg?v=2024-06-11T09:10:49.109Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6666dead8e2e8e00217cb7da/images/1-3.jpg?v=2024-06-11T09:10:49.109Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Text
			font="--headline1"
			color="--darkL1"
			lg-margin="0px 0px 20px 0px"
			sm-margin="0px 0px 10px 0px"
			margin="25px auto 25px auto"
			width="25%"
			md-width="100%"
			md-margin="0 0 20px 0"
			text-align="center"
		>
			ПОСЛУГИ
		</Text>
		<Text
			margin="0px auto 0px auto"
			font="--lead"
			color="--dark"
			max-width="80%"
			text-align="center"
		>
			<Em>
				Пориньте глибше у своє мистецтво з FocusFrame Rentals. Зв’яжіться з нами сьогодні, щоб обговорити потреби вашого проекту та забронювати обладнання та приміщення. Давайте створювати щось неймовірне разом!
			</Em>
		</Text>
		<Section padding="40px 0 50px 0" sm-padding="40px 0 30px 0" quarkly-title="Statistics-11" flex-wrap="wrap">
			<Override
				slot="SectionContent"
				align-items="flex-start"
				flex-direction="row"
				md-flex-wrap="wrap"
				max-width="1440px"
				flex-wrap="wrap"
			/>
			<Box width="50%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text letter-spacing="-.06em" color="--dark" margin="0px 0px 0px 0px" font="--headline2">
					Професійні камери та об’єктиви
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text margin="20px 0px 0px 0px" font="--base" color="--dark">
					Дзеркальні фотокамери високої роздільної здатності: ідеально підходять для зйомки чітких, деталізованих зображень.{"\n"}
					<br />
					Бездзеркальні системи: завдяки легкому дизайну ідеально підходить для зйомки в дорозі.{"\n"}
					<br />
					Спеціальні об’єктиви: від «риб’ячого ока» до телефото — досліджуйте кожен ракурс.
				</Text>
			</Box>
			<Box width="50%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text letter-spacing="-.06em" color="--dark" margin="0px 0px 0px 0px" font="--headline2">
					Постпродакшн пакети
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text margin="20px 0px 0px 0px" font="--base" color="--dark">
					Робочі станції для редагування: оснащені найсучаснішим програмним і апаратним забезпеченням.{"\n"}
					<br />
					Інструменти градації кольорів: налаштуйте свою палітру до досконалості.{"\n"}
					<br />
					Кабінки для редагування звуку: додайте кристально чистий звук до своїх візуальних проектів.
				</Text>
			</Box>
			<Box width="50%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text margin="0px 0px 0px 0px" font="--headline2" letter-spacing="-.06em" color="--dark">
					Освітлення та аксесуари
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text color="--dark" margin="20px 0px 0px 0px" font="--base">
					Набори студійного освітлення: створіть ідеальну атмосферу за допомогою наших регульованих установок.{"\n"}
					<br />
					Модифікатори та гелі: регулюйте світло відповідно до свого настрою чи теми.{"\n"}
					<br />
					Інструменти стабілізації: від штативів до карданних підвісів, гарантуйте, що ваш знімок завжди буде стабільним.
				</Text>
			</Box>
			<Box width="50%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text margin="0px 0px 0px 0px" font="--headline2" letter-spacing="-.06em" color="--dark">
					Студії
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text color="--dark" margin="20px 0px 0px 0px" font="--base">
					Natural Light Studio: залитий денним світлом для м’якого, природного вигляду.{"\n"}
					<br />
					Black Box Studio: ідеально підходить для контрольованого освітлення та висококонтрастних сцен.{"\n"}
					<br />
					Значення зеленого екрана: ідеально підходить для цифрового компонування та ефектів.
				</Text>
			</Box>
		</Section>
		<Text
			margin="0 auto 60px auto"
			font="--headline3"
			color="--dark"
			max-width="70%"
			text-align="center"
		>
			<Em>
				У цьому огляді лише наведено огляд того, що може запропонувати FocusFrame Rentals. Завітайте до нас, щоб побачити весь спектр наших можливостей і обговорити, як ми можемо адаптувати наші ресурси до потреб вашого проекту. Відкрийте для себе весь спектр наших пропозицій, зв’язавшись з нами або відвідавши нас – давайте втілимо ваше творче бачення в реальність!
			</Em>
		</Text>
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});